

.error-heading {
  margin: 50px auto;
  width: 250px;
  border: 5px solid #fff;
  font-size: 126px;
  line-height: 126px;
  border-radius: 30px;
  text-shadow: 6px 6px 5px #000;
}

  .error-heading img {
      width: 100%;
  }

.error-main h1 {
  font-size: 72px;
  margin: 0px;
  color: #F3661C;
  text-shadow: 0px 0px 5px #fff;
  font-family: 'Ropa Sans', sans-serif;
  margin-top: 30px;
  background-color: #F0CA00;
  background-color: #F3661C;
  text-align: center;
  color: #fff;
}