@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  list-style: none;
  text-decoration: none;
}

:root{
  --blue:#368ff3;
  --blue-hover:#0263ca;
}

header {
  padding: 10px 20px;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: var(--blue);
}

.heading {
  width: 100%;
}

.heading > h1 {
  color: #fff;
  font-size: 20px;
}

.quiz-header {
  color: #fff;
}

.user-profile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.user-image {

  right: 0px;
  height: 40px;
  width: auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.questions {
  width: 80vw;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1px solid lightgrey;
}

.question-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  height: 60px;
  padding: 0 30px;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.que-text {
  font-size: 20px;
  font-weight: 600;
}

.question-box {
  padding: 25px 30px 0px 30px;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  -ms-border-radius: 0px 0px 5px 5px;
  -o-border-radius: 0px 0px 5px 5px;
}

.option-list {
  padding: 20px 0;
  display: block;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  background-color: aliceblue;
  border: 1px solid #84c5fe;
  padding: 8px 15px;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3 ease;
  -webkit-transition: all 0.3 ease;
  -moz-transition: all 0.3 ease;
  -ms-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.option:hover {
  background-color: #cce5ff;
  color: #004085;
  border-color: #b8daff;
}

.question-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  padding: 0px 30px;
}

.buttons button {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  margin: 0px 10px 20px 10px;
  height: 45px;
  padding: 0 20px;
  color: #fff;
  background-color: var(--blue);
  border: 1px solid var(--blue);
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3 ease;
  -webkit-transition: all 0.3 ease;
  -moz-transition: all 0.3 ease;
  -ms-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.buttons button:hover {
  background-color: var(--blue-hover);
}

.button-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgrey;
}

.button-icons > button {
  background-color: #fff;
  font-size: 48px;
  border: none;
  outline: none;
  color: var(--blue);
  cursor: pointer;
  margin: 0px 20px;
}

.button-icons button:hover {
  color: var(--blue-hover);
}

.buttons button i {
  color: #fff;
}

.panel {
  display: none;
}

.accordion {
  top: 10px;
  right: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--blue);
  font-size: 48px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  margin: 0px 15px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.accordion >i{
  font-size: 24px;
}

.accordion:hover {
  background-color: var(--blue-hover);
}

.active {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}


.countdown {
  margin: 3px 5vw 0px 1vw;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  background-color: #fff;
  width: 90%;
  height: 45px;
  bottom: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.questions-panel{
  position: fixed;
  top: 55px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
}

.sidenav {
  display: block;
  align-items: center;
  flex-direction: column;
  height: 100%;
  z-index: 1;
  background-color: var(--blue);
  bottom: 0;
  margin-top: 9px ;
  min-height: 90vh;
  
}

.sidenav > ul {
  background-color: var(--blue);
  text-align: center;
  padding: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  max-width: 20vw;
}

.sidenav >ul> li{
  margin-top: 0px;
  flex-basis: 10%;
}
/* .sidenav >ul> li:nth-child(5n) {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 50%;
} */
.sidenav > ul > li > button {
  /* color: var(--blue);
  background-color: #fff; */
  width: 26px;
  height: 26px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.sidenav > ul > li > button:hover {
  color: #fff;
  background-color: var(--blue-hover);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.ansblue {
  color: #fff;
  background-color: var(--blue-hover);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.answhite {
  color: var(--blue);
  background-color: #fff;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
/*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/
/*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/
/*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/
/*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/ /*!LOGIN CONTAINER*/

.login-container{
position: absolute;
top: 50%;
left: 50%;
margin-left:-10rem ;
margin-top:-9.06rem;
  background-color: #fff;
  width:20rem;
  height: auto;
  border: 1px solid var(--blue);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.login-header{
  height: 50px;
  width: 100%;
  background-color: var(--blue);
  border-top-right-radius:10px ;
  border-top-left-radius:10px ;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container > form{
  padding: 10px 20px;
}

.login-container > form > label{
  margin-top: 10px;
}

.login-container > form >input {
  display: block;
  padding: 0px 10px;
  width: 100%;
  height:35px;
  border: 1px solid grey;
  outline: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 10px 0px;
}

.login-container > form > button{
  background-color: var(--blue);
  border: none;
  outline: none;
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 20px auto 10px auto;
  display: flex;
  cursor: pointer;
}

.login-container> form > button:hover{
  background-color: var(--blue-hover);
}

/*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*/
/*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*/
/*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*//*!INFO-CONTAINER*/

.info-container{
  width: 40rem;
  height: auto;
  border: 1px solid var(--blue);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 5px auto;
}

.info-header{
  width: 100%;
  height: 7vh;
  background-color: var(--blue);
  border-top-right-radius:10px ;
  border-top-left-radius:10px ;
  color: #fff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-body{
  overflow-y: scroll;
  width: 40rem;
  height: 80vh;
  padding: 10px 20px;
}

.info-container>.info-body > ul > li{
  margin: 5px 0px;
}

.info-body > .accept{
  display: flex;
  align-items: center;
}

.info-body > .accept > input{
  margin-left: 5px;
  cursor: pointer;
}

.info-footer{
  width: 100%;
  height: 11vh;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius:10px;
  border-top: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-footer > button{
  width: 200px;
  font-size: 20px;
  background-color: var(--blue);
  border: none;
  outline: none;
  color: #fff;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
  cursor: pointer;
  padding: 8px 16px;
}

.info-footer > button:hover{
  background-color: var(--blue-hover);
}

/*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*/
/*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*/
/*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*/
/*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*//*!DISPLAYMESSAGE*/

.display-message{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -200px;
    margin-top: -100px;
  width: 400px;
  height: 200px;
  border:1px solid var(--blue);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.display-message >h1{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid lightgrey;
  background-color: var(--blue);
  color: #fff;
  text-align: center;
  padding: 5px 0px;
  font-size: 24px;
}

.display-message >p{
margin: 15px 20px;
text-align: center;
}

.display-message>div>button{
  margin-top: 5px;
  color: #fff;
  background-color: var(--blue);
  border: none;
  outline: none;
  font-size: 20px;
  padding: 8px 16px;
  border-radius:10px ;
  -webkit-border-radius:10px ;
  -moz-border-radius:10px ;
  -ms-border-radius:10px ;
  -o-border-radius:10px ;
}

.display-message>div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.display-message>div>button:hover{
  background-color: var(--blue-hover);
}