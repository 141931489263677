.result {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  /* border: 1px solid black; */
  /* border-collapse: collapse; */
  text-align: center;
}
.result td,
.result th {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  padding: 5px;
}

.resultChart {
  width: 80%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.resultChart td,
.resultChart th {
  text-align: center;
}
.faiz{
    color:blue;
    font-size: 1.8rem;
}
